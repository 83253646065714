// libsass doesn"t have support for compass sprites, so we no-op this file.
// The sprite-related mixins can be found in
// grappelli_sass_overrides/partials/skins/_grp-default.scss

// This gets included after _grp-default, and unfortunately
// $grp-font-family is not defined with !default
$grp-font-family: Helvetica, "Helvetica Neue", -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;

@mixin grp-font-family {
    font-family: $grp-font-family;
}

.grp-font-family {
    @include grp-font-family;
}
