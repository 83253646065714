// **************************************************
//
// MUELLER
// modular grid system
//
// _media_desktop.scss
//
// 1024px
//
// **************************************************

@import "compass";
@import "compass/layout/grid-background";
@import "compass/typography/vertical_rhythm";

// baseline
$relative-font-sizing: false;
$base-font-size: 12px;
$base-line-height: 16px;
@include establish-baseline(12px);

$gc: 24; // grid columns
$gw: 20px; // grid width
$gm: 20px; // grid margin

.g-d-c {
    @include container;
    width: $gw*$gc+$gm*($gc - 1);
    // uncomment for a background-grid
    // @include grid-background($gc, $gw, $gm, 21px, 0, #ccc, #ddd, #999, false);
}

@include grids(d,
    24 23 22 21 20 19 18 17 16 15 14 13 12 11 10 9 8 7 6 5 4 3 2 1 0, // only add the columns you really need
    $gc,
    $gw,
    $gm,
    24 23 22 21 20 19 18 17 16 15 14 13 12 11 10 9 8 7 6 5 4 3 2 1 0); // only add the pad-units you really need to push/pull/append/prepend

// visibility
.l-show, .h-show, .hp-show, .hl-show, .t-show, .tp-show, .tl-show {
    display: none !important;
}
.d-hide {
    display: none !important;
}
.d-show {
    display: block !important;
}
#{elements-of-type(inline)} {
    &.d-show {
        display: inline !important;
    }
}

.g-d-c {
    @include legacy-pie-clearfix;
    
}
.g-d-c-fluid {
    position: relative;
    float: none;
    clear: both;
    display: table;
    table-layout: fixed;
    width: 100%;
    @include legacy-pie-clearfix;
    > *[class^=g-d] {
        position: relative;
        display: table-cell;
        float: none;
        vertical-align: top;
    }
}
body:not(.rtl) {
    .g-d-c-fluid {
        margin-right: 0 !important;
        padding-right: 0 !important;
        > *[class^=g-d] {
            pmargin-right: 0 !important;
            padding-right: $gm;
            &.g-d-l {
                padding-right: 0;
            }
        }
    }
}
body.rtl {
    .g-d-c-fluid {
        margin-left: 0 !important;
        padding-left: 0 !important;
        > *[class^=g-d] {
            margin-left: 0 !important;
            padding-left: $gm;
            &.g-d-l {
                padding-left: 0;
            }
        }
    }
}
