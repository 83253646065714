// **************************************************
//
// MUELLER
// modular grid system
//
// _layout-two-col.scss
//
// Example of a 2 column layout
//
// **************************************************


@import "compass";

.l-2c {
    @extend .g-d-24;
    // Column 1
    .c-1 {
        @extend .g-d-8;
        @extend .g-d-f;
    }
    // Column 2
    .c-2 {
        @extend .g-d-16;
        @extend .g-d-l;
    }
}

// switched columns
.l-2cr {
    @extend .g-d-24;
    // Column 1
    .c-1 {
        @extend .g-d-8;
        @extend .g-d-f;
        @extend .g-d-push16;
    }
    // Column 1
    .c-2 {
        @extend .g-d-16;
        @extend .g-d-l;
        @extend .g-d-pull8;
    }
}

.l-2c-fluid:not(.grp-cell) {
    @extend .g-d-c-fluid;
    // Column 1
    .c-1 {
        margin-right: 0;
        padding-right: 20px;
        vertical-align: top;
    }
    // Column 2
    .c-2 {
        float: none;
        display: table-cell;
        width: 100%;
        vertical-align: top;
        .grp-row > & {
            padding-right: 0;
        }
    }
    $n: 24;
    @while $n > 0 {
        &.l-d-#{$n} {
            .c-1 {
                @extend .g-d-#{$n}-td;
            }
        }
        $n: $n - 1;
    }
}
.l-2cr-fluid {
    @extend .g-d-c-fluid;
    // Column 1
    .c-1 {
        float: right !important;
        display: table-cell;
        margin-right: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        padding-left: 20px !important;
        vertical-align: top;
    }
    // Column 2
    .c-2 {
        float: right !important;
        display: table-cell;
        margin-right: 0 !important;
        width: 100%;
        @include box-sizing(border-box);
        vertical-align: top;
    }
    $n: 24;
    @while $n > 0 {
        &.l-d-#{$n} {
            .c-1 {
                @extend .g-d-#{$n};
                @extend .g-d-doublepull#{$n};
            }
            .c-2 {
                @extend .g-d-append#{$n};
            }
        }
        $n: $n - 1;
    }
}

.grp-module {
    .l-2c-fluid {
        .c-2 {
            padding-right: 10px;
        }
        &.grp-cell {
            .c-1 {
                display: table-cell;
                vertical-align: top;
            }
            .c-2 {
                display: table-cell;
                * {
                    white-space: pre-wrap; // needed for e.g. .grp-placeholder-related-m2m
                }
                float: none;
                padding-right: 0;
                width: auto !important;
                vertical-align: top;
            }
            $n: 24;
            @while $n > 0 {
                &.l-d-#{$n} {
                    .c-1 {
                        @extend .g-d-#{$n}-td;
                    }
                }
                $n: $n - 1;
            }
        }
    }
}
