@import "compass";

@import "grappelli/overrides/grp-default-overrides";
@import "grappelli/overrides/mixins/rounded-buttons";

// Import standard grappelli styles
@import "grappelli/compass/sass/screen";

// Grappelli sets font-smoothing to antialiased, which is inferior to
// subpixel-antialiased on LCD screens. So we unset it so that it uses
// the user-agent default
body {
    -webkit-font-smoothing: unset !important;
    -moz-osx-font-smoothing: unset !important;
}

// Grappelli's "normalize" styles are a bit dumb
b, strong { font-weight: bold; }
i, em, cite { font-style: italic; }

// Grappelli makes the h1 32px, which is a bit too large.
h1, .h1 {
    font-size: 18px;
    line-height: 23px;
    padding: 15px 0 12px 0;
}

#grp-container:after {
  content: '';
  display: block;
  clear: both;
}

#grp-content {
    padding-bottom: 50px;
}

.grp-pagination ul li {
    @include border-radius(5px);
    a, span {
        @include border-radius(4px);
    }
}

// Oh grappelli... smh
body.grp-change-form #grp-content-container > form, body.grp-change-form #grp-content-container > form > div {
    width: 100%;
}

table.grp-table {
    // There is no way with grappelli's scss to change the font-size, line-height,
    // or padding of table cells. By default the font is too small and the padding
    // is excessive.
    td, th, thead th {
        font-size: 12px;
        line-height: 14px;
        padding: 5px 10px;
    }
    // Grappelli makes the color in thead too light
    thead th, thead th a {
        color: #222;
    }
    tbody tr {
        // The default link color in tables is difficult to read against the
        // background-color of even rows
        th a, td a {
            color: #902;
        }

        &.grp-row-even td, &.grp-row-even th, 
        &.grp-alt td, &.grp-alt th {
            background: #e9f1f5;
        }
    }

    // Make changelist tables slightly more compressed, and center the action
    // checkbox
    body.grp-change-list .grp-changelist-results > & {
        margin: 0 !important;
        border: 0;
        width: 100%;

        .action-checkbox-column {
            width: 30px;
        }
        .action-checkbox {
            text-align: center;
        }
    }
}

// django-categories styles
table#result_list.treeTable {
    tbody tr.grp-row-odd, tbody tr.grp-row-even {
        td, th {
            background: #eee;
        }
    }
    tr > td:first-child {
        padding-left: 10px !important;
    }
    .action-select {
        margin-left: 10px !important;
    }
    .expander {
        position: absolute;
        width: 13px;
        height: 14px;
        margin-left: -10px !important;
        padding-right: 13px !important;
        padding-left: 0px !important;
    }
}
body.grp-change-list .grp-changelist-results > table.treeTable .action-checkbox-column {
    width: 22px;
}

#grp-content {
    .rounded-button,
    input[type="submit"].rounded-button,
    input[type="submit"].grp-button,
    input[type="submit"].grp-default,
    input[type="button"].rounded-button {
        @include atl-rounded-button(blue);
    }

    a.rounded-button {
        @include atl-rounded-button(grey);
    }

    .rounded-button.blue,
    a.rounded-button.blue,
    input[type="submit"].rounded-button.blue,
    input[type="button"].rounded-button.blue {
        @include atl-rounded-button(blue);
    }

    a.grp-button.grp-delete-link,
    a.grp-button.grp-cancel-link {
        @include atl-rounded-button(red);
    }

    .rounded-button.red,
    a.rounded-button.red,
    input[type="submit"].rounded-button.red,
    input[type="button"].rounded-button.red {
        @include atl-rounded-button(red);
    }
}

.grp-object-tools {
    @include horizontal-list(5px, left);
    position: relative;
    float: right;
    top: -40px;
    margin: 0 0 -40px;
    li {
        a {
            @include atl-rounded-button(grey);

            &.grp-add-link {
                @include atl-rounded-button(blue, 13px, sprite('', object-tools-add-link) no-repeat);
            }
            &.grp-viewsite-link, 
            &[target="_blank"] {
                @include atl-rounded-button(blue, 13px, sprite('', object-tools-viewsite-link) no-repeat);
            }
        }
    }
}

// Styles for logo, header, dropdown nav menu, and breadcrumbs
#grp-context-navigation {
    font-weight: normal;
}
header#grp-header #grp-navigation {
    body.development & {
        background: #98A9B5;
    }
    font-size: 11px;
    font-weight: bold;
    line-height: 14px;

    h1#grp-admin-title {
        z-index: 800;
        height: 18px;
        line-height: 16px;
        background: url("../../custom_admin/img/new-ollie-logo.svg") no-repeat 0 2px;
        padding: 8px 0 8px 60px;
    }
    a {
        color: #fff;
    }
    ul#grp-user-tools {
        position: absolute;
        right: 0;

        &, > li {
            border: 0;
        }
        > li .grp-user-options a {
            padding: 0;
        }

        .user-options-handler {
            text-align: right;
            letter-spacing: 2px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            padding: 8px 20px 8px 10px;
            text-transform: uppercase;
        }
        .user-options-handler:after { 
            content: '\2630'; /*Unicode burger*/
            font-size: 1.5em;
            vertical-align: text-bottom;
            margin: 0 0 0 6px;
            font-weight: normal;
        }

        .grp-user-options {
            margin: 3px 0 0 0;
            background: rgba(0, 0, 0, 0.85);
            overflow:hidden;
            position: relative;
            padding: 10px;
            box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.85);
            li {
                float:none;
                padding: 6px 8px;
            }
            // Compress the menu on small screen
            @media (max-height: 550px) {
                &, & li {
                    padding-top: 2px;
                    padding-bottom: 2px;
                }
            }
        }

        .panel {
            float: left;
            display: inline-block;
            border-right: 1px solid #333;
            margin-right: -1px;
            &:first-child { border-right: 0; }
        }

        li.category {
            font-size: 0.9em;
            text-transform: uppercase;
            padding: 9px 9px 0 9px;
            margin: 0;
            color: #666;
            border-bottom: 1px solid #333;
            padding-bottom: 2px;
            margin-bottom: 3px;
        }
    }
}


#atl-breadcrumbs {
    float: left;  // Because the documentation link is floated right
}

#grp-header > ul.grp-horizontal-list, #atl-breadcrumbs > ul {
    @include horizontal-list(0, left);
    padding: 0 0 0 20px;
    font-size: 12px;
    height: 24px;

    li {
        padding: 5px 12px;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAfCAYAAAAx6zerAAABiUlEQVQ4y2OQlZeXPXHyZMjz588VgZgBH2YAKubOzMqyB3JSCSr+//8/SIP89h074oAClsQoZnF2dTV6+PBhDlCQA69iEAOoQXzW7NlBQHYAQcVQDeqXLl0CmS5LjGK+0rIyFyA7iaBiqAbFffv2JQLZxsQoZvPx8zN9/PhxFpDPilcxVIPk4sWLQ4FsL2IUM2rp6Ghfv34d5FkxvIqhGgQam5rcgexYgoqhGpSPHjsGSgbaxCjmiIqOtnr27FkmkM+IVzFUg8zqNWuigGwXYhQz6ejqOgDZM4hRzJdXUAAKwiJiFKtfvnIlG8iWJuRB8Xnz5oFSoi+hcGaxd3Q0fPDgAShiuAgplt+1a1cCkG1BKLq5c/LyQCGQQkzaUDt37hwo1SkRSqIiEydNAnkohFBOYTIwNNS9c+cOyFP8hBTLbti4MRrIdiCUuznjEhJsnj59Cko0zIQUq5w8eTIDyNYgVMgItnd0eAIFIgmVdQwqampaN2/ezAUKCBNSLLVy5coIIMeNUMEIAOFVTEhV/w15AAAAAElFTkSuQmCC");
        background-repeat: no-repeat;
        background-position: right -4px;

        &:last-child {
            background: transparent;
        }
    }
    a {
        text-shadow: 0 1px 0 #f5f5f5;
        display: block;
        padding-right: 15px;
    }
}

.nav-documentation {
    float: right;
    padding: 5px 20px 5px 0;
    height: 24px;
    line-height: 14px;
    box-sizing: border-box;
}

#grp-page-tools {
    display: none;
}

.grp-collapse-handler + .grp-tools li.grp-drag-handler-container {
    display: none;
}
div.grp-readonly {
    border-color: transparent;
}

.grp-tools li.grp-open-handler-container,
.grp-tools li.grp-close-handler-container,
.grp-tools li.grp-add-handler-container {
    display: none;
}

.has_original > .djn-collapse-handler > .djn-collapse-handler-verbose-name {
    display: none !important;
}
.djn-inline-form:not(.has_original) > .djn-collapse-handler > .djn-collapse-handler-verbose-name {
    text-transform: capitalize;
}

// Grappelli oddly makes all input fields bold. Undo this.
input[type="text"], 
input[type="password"], 
input[type="url"], 
input[type="email"], 
input[type="number"], 
textarea, 
select:not(.filtered) {
    font-weight: normal;
    &:focus {
        @include box-shadow(rgb(59, 153, 252) 0 0 3px 2px);
    }
}

// TODO: these cropduster style tweaks belong in django-cropduster, not here
.cropduster-form input[type=text] {
    width: 588px;
}
.cropduster-form .grp-module,
.grp-group:not(.grp-tabular) .grp-module .cropduster-form .grp-module {
    margin-top: 6px;
    border-top: 1px solid #ddd;
}
.cropduster-form .grp-module .grp-row.alt_text {
    border-bottom: 0;
}
.cropduster-upload-form #crop_nav {
    margin-top: 6px;
}
.cropduster-upload-form #current-thumb-info {
    margin-top: 4px;
}
.cropduster-upload-form #error-container {
    overflow: hidden;
}

// The grappelli footer is 40 pixels taller than it needs to be.
.grp-fixed-footer {
    padding: 0 20px;

    &.grp-submit-row  > ul > li {
        margin-bottom: 0;
        background: transparent;
    }
}

#grp-content > .grp-messagelist {
    padding: 20px 0 0 0;
    .grp-popup & {
        padding: 0 0 20px 0;
    }
}

.grp-errors label {
    color: #222 !important;
}

.grp-messagelist {
    li {
        background: $grp-messagelist-background-color;
        @include text-shadow(0 1px 0 rgba(255, 255, 255, 0.4));
        color: #3a87ad;
        font-size: 1.1em;

        &.grp-success, &.success {
            border: 1px solid $grp-messagelist-success-border-color;
            background: $grp-messagelist-success-background-color;
            color: #468847;
        }
        &.grp-info, &.info {
            color: #3a87ad;
            background: #d9edf7;
            border-color: #bce8f1;

            a:link, a:visited, a:active, a:hover, a:focus {
                color: #267094;
                text-decoration: underline;
            }
            a:hover {
                color: #000;
            }
        }
        &.grp-warning, &.warning {
            border: 1px solid $grp-messagelist-warning-border-color;
            background: $grp-messagelist-warning-background-color;
            color: #222;
        }
        &.grp-error, &.error {
            border: 1px solid $grp-messagelist-error-border-color;
            background: $grp-messagelist-error-background-color;
            color: #fff;
            @include text-shadow(0 1px 0 rgba(0, 0, 0, 0.4));
        }
        &.grp-warning, &.grp-error, &.warning, &.error {
            a:link, a:visited {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}

#locking-warning {
    line-height: 25px;

    > #locking-take-lock {
        float: right;
    }
}

// Hide pagination toolbars on changelist view when there are no filters
// and no pagination.
body.changelist-no-top-nav #grp-content-container > div[class="grp-module"],
body.changelist-no-top-nav #grp-changelist + div[class="grp-module"] {
    display: none;
}

// Grappelli 2.3 overrides
select[multiple] {
    margin: 0 0 0 -1px;
    padding-left: 3px;
    max-width: 368px !important;
    width: 368px !important;
    height: 200px;
    @include border-radius(0);

}
.selector-chosen select[multiple] {
    height: 235px !important;
}

body.grp-login .grp-module-login {
  background: transparent;
}
body.grp-login .grp-module-login h1 {
  padding: 0;
}
body.grp-login .grp-module-login h1 span.grp-admin-title {
  display: none;
}
.password-recovery #id_username {
  width: 100%;
}
