


/*  DELETE CONFIRMATION
------------------------------------------------------------------------------------------------------ */

body.grp-delete-confirmation {
    ul.grp-nested-list {
        position: relative;
        float: left;
        clear: both;
        width: 100%;
        margin: -2px 0 2px;
        li {
            font-size: 12px;
            font-weight: normal;
            > ul li > ul {
                margin-left: 6px;
                > li {
                    margin: 5px 0 5px -4px;
                    padding-left: 10px;
                    border-left: 4px solid #ddd;
                }
            }
            & + li {
                margin-top: 5px;
            }
        }
        > li {
            margin-left: 0;
            font-size: 14px;
            font-weight: bold;
            @include grp-module;
            margin-top: 2px !important;
            margin-bottom: 0 !important;
            padding: 8px 10px;
            & + li {
                margin-top: 0;
            }
            > ul {
                margin-top: 8px;
                border-top: 1px solid #ddd;
                > li {
                    margin-top: 0 !important;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    font-size: 13px;
                    font-weight: bold;
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #ddd;
                    &:last-child {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                    > ul {
                        margin-top: 8px;
                        > li {
                            font-weight: bold;
                            > ul li {
                                ul li {
                                    color: $grp-font-color-quiet;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}