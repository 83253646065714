


/*  Widget Basics
------------------------------------------------------------------------------------------------------ */

.module.ui-widget {
    border: none;
    background: #fff;
}
.ui-widget-content {
    border: 1px solid #ccc;
    border-bottom-left-radius: 5px; -moz-border-radius-bottomleft: 5px; -webkit-border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; -moz-border-radius-bottomright: 5px; -webkit-border-bottom-right-radius: 5px;
    background: #eee;
}



/*  Sortable
------------------------------------------------------------------------------------------------------ */

.ui-sortable-helper, 
.ui-sortable-placeholder {
    opacity: .8;
}

.ui-sortable-placeholder, 
.ui-sortable .module.ui-sortable-placeholder {
    border: 1px solid #bdbdbd;
    border-radius: 4px; -moz-border-radius: 4px; -webkit-border-radius: 4px;
    background: transparent url(../img/backgrounds/ui-sortable-placeholder.png) 0 0 repeat scroll !important;
}
.ui-sortable-placeholder.group {
    border-width: 2px;
    border-radius: 7px; -moz-border-radius: 7px; -webkit-border-radius: 7px;
}
.ui-sortable-placeholder.group * {
    visibility: hidden !important;
}

.group.stacked .ui-sortable .module.ui-sortable-placeholder {
    display: block;
    margin-top: 2px;
}
.group.tabular .ui-sortable .module.ui-sortable-placeholder {
    border: 0 !important;
    overflow: hidden;
}
.group.tabular .ui-sortable .module.ui-sortable-placeholder .td {
    background: transparent;
}
.group.tabular .module.ui-sortable-helper {
    border-top: 0 !important;
    -moz-box-sizing: padding-box !important;
}



/*  Accordion
------------------------------------------------------------------------------------------------------ */


/* Overlays */
.ui-accordion .ui-accordion-header { cursor: pointer; position: relative; margin-top: 1px; zoom: 1; }
.ui-accordion .ui-accordion-li-fix { display: inline; }
.ui-accordion .ui-accordion-header-active { border-bottom: 0 !important; }
.ui-accordion .ui-accordion-header a {
    display: block;
    font-size: 1em;
    padding: 0 0 0 12px;
}
.ui-accordion .ui-accordion-header .ui-icon { display: none; }
.ui-accordion .ui-accordion-content {
    top: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
/*    border-top: 1px solid #fff;*/
}
.ui-accordion .ui-accordion-content-active { display: block; }



/*  Datepicker
----------------------------------*/
.datetime br {
    display: none;
}
.datetimeshortcuts {
    width: 40px;
    position: relative;
    margin-left: 10px;
}
.datetimeshortcuts a {
    margin-left: 0 !important;
}

.ui-accordion-header {
    margin-top: 2px !important;
    cursor: pointer;
    outline: none;
}
.ui-accordion .ui-accordion-header a {
    padding: 0 0 0 12px;
    color: #444;
    outline: none;
}
.ui-accordion .ui-accordion-header {
    display: block;
    margin: 0;
    padding: 6px 0;
    outline: none;
    font-size: 12px;
    border: 1px solid #bdbdbd !important;
    background: #cee9f2;
    background: -moz-linear-gradient(top, #e1f0f5, #cee9f2);
    background: -webkit-gradient(linear, left top, left bottom, from(#e1f0f5), to(#cee9f2));
    background: -o-linear-gradient(top, #e1f0f5, #cee9f2);
}

.ui-accordion-header.ui-state-default {
    border-radius: 5px; -moz-border-radius: 5px; -webkit-border-radius: 5px;
}
.ui-accordion-header.ui-state-hover {
    background: #cee9f2;
    background: -moz-linear-gradient(top, #cee9f2, #e1f0f5);
    background: -webkit-gradient(linear, left top, left bottom, from(#cee9f2), to(#e1f0f5));
    background: -o-linear-gradient(top, #cee9f2, #e1f0f5);
}
.ui-accordion-header.ui-state-active {
    border-bottom: 1px solid #c7c7c7 !important;
    border-bottom-left-radius: 0; -moz-border-radius-bottomleft: 0; -webkit-border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; -moz-border-radius-bottomright: 0; -webkit-border-bottom-right-radius: 0;
    background: #cee9f2;
    background: -moz-linear-gradient(top, #cee9f2, #e1f0f5);
    background: -webkit-gradient(linear, left top, left bottom, from(#cee9f2), to(#e1f0f5));
    background: -o-linear-gradient(top, #cee9f2, #e1f0f5);
}

.ui-accordion-content {
    border-top: 0 !important;
    border-top-left-radius: 0; -moz-border-radius-topleft: 0; -webkit-border-top-left-radius: 0;
    border-top-right-radius: 0; -moz-border-radius-topright: 0; -webkit-border-top-right-radius: 0;
}
.ui-accordion-content h3 {
    display: none;
}
.ui-accordion-content .module:first-child {
    margin-top: 0 !important;
    border-top-color: #f4f4f4 !important;
}
.module.accordion>.module {
    margin-bottom: 2px;
    border-top: 0 !important;
}
.module.accordion>.module:last-of-type {
    margin-bottom: 0;
}


/*  Accordion Module ......................................... */

.ui-accordion-header.ui-state-default, 
.module .ui-accordion-header.ui-state-default {
    border: 1px solid #bdbdbd;
    background-color: #a1d4e5;
}
.ui-accordion-header.ui-state-default:hover, 
.module .ui-accordion-header.ui-state-default:hover {
    background-color: #d6d6d6;
}
.ui-accordion-header.ui-state-active, 
.module .ui-accordion-header.ui-state-active {
    border: 1px solid #bdbdbd;
    background-color: #d6d6d6;
}



/*  Accordion Module in Group......................................... */

/*.group .module .ui-accordion-header.ui-state-default {
    border: 1px solid #c7c7c7;
    background-color: #cee9f2;
}
.group .module .ui-accordion-header.ui-state-default:hover {
    background-color: #e0e0e0;
}
.group .module .ui-accordion-header.ui-state-active {
    border: 1px solid #c7c7c7;
    background-color: #e0e0e0;
}
.group .module .ui-accordion-header {
    border-top: 1px solid #4ef;
}
*/


/*  Datepicker
------------------------------------------------------------------------------------------------------ */

.ui-datepicker {
    width: auto !important; padding: 3px 3px 0;
    border-color: #bdbdbd;
    box-shadow: 0 10px 50px #333; -moz-box-shadow: 0 10px 50px #333; -webkit-box-shadow: 0 10px 50px #333;
}
.ui-datepicker .ui-datepicker-header {
    padding: 2px 0;
    height: 25px;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next, 
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
    position: absolute;
    top: 4px;
    width: 20px;
    height: 30px;
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev {
    left: 3px;
    background-image: url(../img/icons/ui-datepicker-prev.png);
}
.ui-datepicker .ui-datepicker-prev-hover {
    left: 3px;
    border: none;
    background-image: url(../img/icons/ui-datepicker-prev-hover.png);
}
.ui-datepicker .ui-datepicker-next {
    right: 3px;
    background-image: url(../img/icons/ui-datepicker-next.png);
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 3px;
    border: none;
    background-image: url(../img/icons/ui-datepicker-next-hover.png);
}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
    display: none !important;
}


.ui-datepicker .ui-datepicker-title {
    margin: 3px 25px 2px;
    line-height: 1.8em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    float:left;
    font-size:1em;
    margin: -3px 0 -1px !important;
    min-width: 30px;
}
.ui-datepicker select.ui-datepicker-month-year {width: 100%;}
.ui-datepicker select.ui-datepicker-month, 
.ui-datepicker select.ui-datepicker-year { width: 49%;}
.ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
    float: right;
}
.ui-datepicker table {
    width: 100%;
    font-size: 12px;
    margin: 0 0 2px;
}
.ui-datepicker th {
    padding: 5px 0;
    text-align: center;
    font-weight: bold;
    border: 0;
    background: transparent;
}
.ui-datepicker td {
    min-width: 25px;
    border: 0; padding: 1px;
}
.ui-datepicker td span, .ui-datepicker td a {
    padding: 4px 0 3px;
    margin:0!important;
    text-align: center;
    display:block;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px;
}
.ui-datepicker td a.ui-state-hover {
    color: #fff !important;
    border-color: transparent !important;
    background: #444 !important;
}
.ui-datepicker td a.ui-state-active {
    background: #fff;
}
.ui-datepicker td a.ui-state-highlight {
    border-color: #bababa;
    background: #d6d6d6;
}
.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: 5px 0 0;
    padding: 0;
    border: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: 3px 0;
    padding: 4px 5px 5px;
    height: 25px;
    color: #aaa; font-size: 11px;
    border: 1px solid #c7c7c7;
    background: transparent;
    cursor: pointer;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .ui-datepicker .ui-datepicker-buttonpane button {
        padding: 5px 8px 4px;
    }
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    opacity: 1 !important;
    color: #444; font-weight: bold;
    background: #cee9f2;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-state-hover {
    color: #fff !important;
    border-color: #444 !important;
    background: #444 !important;
}

.ui-datepicker-multi .ui-datepicker-group-first .ui-datepicker-title, 
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-title {
    margin-right: 5px !important;
}
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-title, 
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-title {
    margin-left: 5px !important;
}

.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
}
.ui-datepicker-multi .ui-datepicker-group-first table, 
.ui-datepicker-multi .ui-datepicker-group-middle table {
    margin-right: 5px !important;
}
.ui-datepicker-multi .ui-datepicker-group-middle table, 
.ui-datepicker-multi .ui-datepicker-group-last table {
    margin-left: 5px !important;
}
.ui-datepicker-multi .ui-datepicker-group-middle table {
    margin-left: 3px !important;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
    border: none;
}

.ui-datepicker-append {
    margin-left: 6px; color: #999; font-size: 10px;
}

.ui-datepicker td.ui-state-disabled {
    padding:1px;
    text-align: center;
}
.ui-datepicker td.ui-state-disabled span {
    background: #ccc;
    color: #555 !important;
    font-weight: bold;
    font-size: 11px;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-borderradius: 3px;
}
button.ui-datepicker-close {
    float: left !important;
    margin-right: 4px !important;
}



/*  Timepicker
------------------------------------------------------------------------------------------------------ */

#ui-timepicker {
    padding: 5px 3px 3px 5px;
    width: 216px;
    border: 1px solid #bdbdbd;
    box-shadow: 0 10px 50px #333; -moz-box-shadow: 0 10px 50px #333; -webkit-box-shadow: 0 10px 50px #333;
}
#ui-timepicker ul {
    position: relative;
    float: left;
    clear: both;
    width: auto;
}
#ui-timepicker ul li.row {
    position: relative;
    float: left;
    display: block;
    margin: 0 2px 2px 0;
    padding: 2px 10px 1px;
    width: 30px;
    font-size: 11px;
    text-align: center;
    border: 0;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-borderradius: 3px;
    cursor: pointer;
}
#ui-timepicker .row.ui-state-default {
    border: 1px solid #c7c7c7 !important;
    background: #e1f0f5;
}
#ui-timepicker .row.ui-state-active {
    border: 1px solid #bababa !important;
    background: #d6d6d6;
}
#ui-timepicker .row.ui-state-default:hover {
    color: #fff;
    border: 1px solid #666 !important;
    background: #444;
}



/*  Tabs
------------------------------------------------------------------------------------------------------ */

.ui-tabs {
    zoom: 1;
    border: 0 !important;
    background: transparent;
}
.ui-tabs .ui-tabs-nav {
    margin-top: 2px;
    padding: 0;
    color: #444;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid #bdbdbd;
    border-bottom-left-radius: 0; -moz-border-radius-bottomleft: 0; -webkit-border-bottom-left-radius: 0;
    background: none;
}
.ui-tabs:first-child .ui-tabs-nav {
    margin-top: 0;
}
.ui-tabs .ui-tabs-nav li {
    position: relative; float: left;
    border-bottom-width: 1px !important;
    margin: 0 2px -1px 0;
    padding: 0;
}
.ui-tabs .ui-tabs-nav li a {
    float: left;
    text-decoration: none;
    padding: 6px 10px 6px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-selected {
    padding-bottom: 0px; border-bottom-width: 1px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-selected a, .ui-tabs .ui-tabs-nav li.ui-state-disabled a, .ui-tabs .ui-tabs-nav li.ui-state-processing a { cursor: text; }
.ui-tabs .ui-tabs-nav li a, .ui-tabs.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-selected a { 
    cursor: pointer; 
} /* first selector in group seems obsolete, but required to overcome bug in Opera applying cursor: text overall if defined elsewhere... */
.tab-handler.ui-state-default {
    background: #e1f0f5;
    background: -moz-linear-gradient(top, #cee9f2, #e1f0f5);
    background: -webkit-gradient(linear, left top, left bottom, from(#cee9f2), to(#e1f0f5));
    background: -o-linear-gradient(top, #cee9f2, #e1f0f5);
}
.tab-handler.ui-state-default:hover {
    color: #444 !important;
    border: 1px solid #c7c7c7;
    background: #cee9f2;
    background: -moz-linear-gradient(top, #e1f0f5, #cee9f2);
    background: -webkit-gradient(linear, left top, left bottom, from(#e1f0f5), to(#cee9f2));
    background: -o-linear-gradient(top, #e1f0f5, #cee9f2);
}
.tab-handler.ui-state-default.ui-tabs-selected {
    border: 1px solid #c7c7c7;
    border-bottom-color: #d4d4d4;
    background: #e9e9e9;
    background: -moz-linear-gradient(top, #e0e0e0, #e9e9e9);
    background: -webkit-gradient(linear, left top, left bottom, from(#e0e0e0), to(#e9e9e9));
    background: -o-linear-gradient(top, #e0e0e0, #e9e9e9);
}


.ui-tabs-nav li a:hover {
    color: #444 !important;
}
.ui-tabs-nav li.ui-tabs-selected a {
    color: #444 !important;
}
.ui-tabs .ui-tabs-panel {
    margin-top: 0 !important;
    padding: 0;
    display: block;
    border: 1px solid #ccc;
    border-top-left-radius: 0; -moz-border-radius-topleft: 0; -webkit-border-top-left-radius: 0;
    border-top-right-radius: 4px; -moz-border-radius-topright: 4px; -webkit-border-top-right-radius: 4px;
    border-bottom-left-radius: 5px; -moz-border-radius-bottomleft: 5px; -webkit-border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; -moz-border-radius-bottomright: 5px; -webkit-border-bottom-right-radius: 5px;
    background: #eee;
}
.ui-tabs-panel h3 { display: none; }
.ui-tabs-panel > h3 + .module {
    border-top-right-radius: 5px; -moz-border-radius-topright: 5px; -webkit-border-top-right-radius: 5px;
}
.ui-tabs-panel > h3 + .module > h4:first-child {
    margin-top: -1px;
/*    border-top: 0 !important;*/
    border-top-right-radius: 4px; -moz-border-radius-topright: 4px; -webkit-border-top-right-radius: 4px;
}
.ui-tabs .ui-tabs-hide { display: none !important; }

/*.group-accordion-container h3 { display: none; }*/





/* Menu
----------------------------------*/
.ui-menu {
    list-style:none;
    padding: 2px;
    margin: 0;
    display:block;
}
.ui-menu .ui-menu {
    margin-top: -3px;
}
.ui-menu .ui-menu-item {
    margin: 0;
    padding: 0;
    width: 100%;
}
.ui-menu .ui-menu-item a {
    text-decoration: none;
    display: block;
    padding: 5px 5px 4px;
}
.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-active {
/*    margin: -1px;*/
    border: 0 !important;
}


/*  Autocomplete
------------------------------------------------------------------------------------------------------ */

.ui-autocomplete {
    position: absolute;
    cursor: default;
/*    max-height: 100px;*/
/*    overflow-y: auto;*/
    border-color: #999 #bbb #bbb #999;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px;
    background: #fff;
    box-shadow: 0 10px 50px #333; -moz-box-shadow: 0 10px 50px #333; -webkit-box-shadow: 0 10px 50px #333;
}
* html .ui-autocomplete {
    width: 1px;
}
.ui-autocomplete-category {
    font-weight: bold;
    line-height: 1.5;
    font-style: italic;
    margin: 0;
    padding: 5px;
}
.ui-autocomplete .ui-menu-item a {
    padding: 5px;
    font-weight: bold;
    border-top: 1px solid #e0e0e0 !important;
    border-radius: 2px; -moz-border-radius: 2px; -webkit-border-radius: 2px;
}
.ui-autocomplete .ui-menu-item a:hover, .ui-autocomplete .ui-menu-item a:active {
    color: #fff;
    border-top: 1px solid transparent !important;
    background: #444;
}
.ui-autocomplete .ui-menu-item:first-child a {
    border-top: 0 !important;
}
.ui-autocomplete .ui-menu-item:hover + li a {
    border-top-color: 0 !important;
}



/*  Facelist
------------------------------------------------------------------------------------------------------ */
.ui-gFacelist-wrapper {
    margin-left:160px;
    width:758px;
}

.ui-gFacelist-wrapper.focus {
    border-color: #999 #BBB #BBB #999;
}

.ui-gFacelist-toolbar button {
    border-bottom-right-radius: 0; -moz-border-radius-bottomright: 0; -webkit-border-bottom-right-radius: 0;
}

ul.ui-gFacelist-facelist {
    clear:both;
    padding: 5px 5px 5px 0;
    position: relative; float: left; clear: both;
    width:758px;
    box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;
}

li.ui-gFacelist-item {
    cursor: pointer;
    background:#ddd;
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ebf2f5), to(#e1f0f5));
    background-image: -moz-linear-gradient(top, #ebf2f5, #e1f0f5);
    background-image: -o-linear-gradient(top, #ebf2f5, #e1f0f5);
    background-repeat:no-repeat;
    border:1px solid #ccc;
    border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px;
    -webkit-box-sizing: border-box;
    float: left;
    font-weight: bold;
    margin-top: 5px; 
    margin-right: 5px;
    padding: 4px 7px 3px 4px;
    opacity: 1;    
    position: relative; 
    width: auto;
}

li.ui-gFacelist-item:hover {
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#e1e1e1), to(#eee));
    background-image: -moz-linear-gradient(top, #e1e1e1, #eee);
    background-image: -o-linear-gradient(top, #e1e1e1, #eee);
}

li.ui-gFacelist-item span {
    background:transparent url(../img/icons/icon-bookmark_remove-inactive.png) no-repeat 2px 2px;
    padding:0 0 0 18px;
}

li.ui-gFacelist-item:hover span {
    background:transparent url(../img/icons/icon-bookmark_remove.png) no-repeat 2px 2px;
}



/*  AutoSlugField
------------------------------------------------------------------------------------------------------ */

.ui-gAutoSlugField-preview {
    display:block;
    color:#999;
    padding:2px 6px;
    background:#eee;
    border:1px solid #ddd;
    border-width:0 1px 1px 1px;
    margin-left:160px;
    width:744px;
}
