$icons-sprites: "";

@function sprite($icons-sprite, $icon, $offset: 0) {
    @return url('../../grappelli/images/icons/#{$icon}.png') 0 0;
}
@mixin all-icons-sprites($vars...) {}
@mixin all-icons-small-sprites($vars...) {}
@mixin icons-small-sprite($icon, $offset-x: 0, $offset-y: 0) {
    background: url("../../grappelli/images/icons-small/#{$icon}.png") no-repeat $offset-x $offset-y;
}
@mixin icons-sprite($icon, $offset-x: 0, $offset-y: 0) {
    background: url("../../grappelli/images/icons/#{$icon}.png") no-repeat $offset-x $offset-y;
}

$grp-font-family: "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;

$grp-font-color: #222;
$grp-font-color-quiet: #666;
$grp-context-navigation-background-color: #f4f4f4;
$grp-table-background-color: #f4f4f4;
$grp-table-border-color: #d4d4d4;
$grp-form-field-font-family: $grp-font-family;
$grp-form-field-border: 1px solid #aaa;
$grp-form-label-font-family: $grp-font-family;
$grp-form-label-font-color: #222;
$grp-form-button-font-family: $grp-font-family;
$grp-form-field-font-color: #222;
$grp-form-field-font-size: 12px;
$grp-form-label-font-size: 12px;

$grp-messagelist-background-color: #fcf8e3;
$grp-messagelist-success-background-color: #dff0d8;
$grp-messagelist-warning-background-color: #fddc0d;
$grp-messagelist-error-background-color: #b11c12;
$grp-messagelist-warning-border-color: #ab9300;