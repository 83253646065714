


/*  CONSTANTS
------------------------------------------------------------------------------------------------------ */

@mixin grp-margin($grp-margin) {
    padding: $grp-margin;
}
@mixin grp-padding($grp-padding) {
    padding: $grp-padding;
}



/*  EXTENSIONS (VALUES DEFINED IN SKIN/GRP_DEFAULT)
------------------------------------------------------------------------------------------------------ */

.grp-margin-xl { margin: $grp-margin-size-xl !important; }
.grp-margin-l { margin: $grp-margin-size-l !important; }
.grp-margin-m { margin: $grp-margin-size-m !important; }
.grp-margin { margin: $grp-margin-size !important; }
.grp-margin-s { margin: $grp-margin-size-s !important; }
.grp-margin-xs { margin: $grp-margin-size-xs !important; }

.grp-margin-top-xl { margin-top: $grp-margin-size-xl !important; }
.grp-margin-top-l { margin-top: $grp-margin-size-l !important; }
.grp-margin-top-m { margin-top: $grp-margin-size-m !important; }
.grp-margin-top { margin-top: $grp-margin-size !important; }
.grp-margin-top-s { margin-top: $grp-margin-size-s !important; }
.grp-margin-top-xs { margin-top: $grp-margin-size-xs !important; }

.grp-margin-bottom-xl { margin-bottom: $grp-margin-size-xl !important; }
.grp-margin-bottom-l { margin-bottom: $grp-margin-size-l !important; }
.grp-margin-bottom-m { margin-bottom: $grp-margin-size-m !important; }
.grp-margin-bottom { margin-bottom: $grp-margin-size !important; }
.grp-margin-bottom-s { margin-bottom: $grp-margin-size-s !important; }
.grp-margin-bottom-xs { margin-bottom: $grp-margin-size-xs !important; }

.grp-margin-left-xl { margin-left: $grp-margin-size-xl !important; }
.grp-margin-left-l { margin-left: $grp-margin-size-l !important; }
.grp-margin-left-m { margin-left: $grp-margin-size-m !important; }
.grp-margin-left { margin-left: $grp-margin-size !important; }
.grp-margin-left-s { margin-left: $grp-margin-size-s !important; }
.grp-margin-left-xs { margin-left: $grp-margin-size-xs !important; }

.grp-margin-right-xl { margin-right: $grp-margin-size-xl !important; }
.grp-margin-right-l { margin-right: $grp-margin-size-l !important; }
.grp-margin-right-m { margin-right: $grp-margin-size-m !important; }
.grp-margin-right { margin-right: $grp-margin-size !important; }
.grp-margin-right-s { margin-right: $grp-margin-size-s !important; }
.grp-margin-right-xs { margin-right: $grp-margin-size-xs !important; }

.grp-margin-vertical-xl { margin-top: $grp-margin-size-xl !important; margin-bottom: $grp-margin-size-xl !important; }
.grp-margin-vertical-l { margin-top: $grp-margin-size-l !important; margin-bottom: $grp-margin-size-l !important; }
.grp-margin-vertical-m { margin-top: $grp-margin-size-m !important; margin-bottom: $grp-margin-size-m !important; }
.grp-margin-vertical { margin-top: $grp-margin-size !important; margin-bottom: $grp-margin-size !important; }
.grp-margin-vertical-s { margin-top: $grp-margin-size-s !important; margin-bottom: $grp-margin-size-s !important; }
.grp-margin-vertical-xs { margin-top: $grp-margin-size-xs !important; margin-bottom: $grp-margin-size-xs !important; }

.grp-margin-horizontal-xl { margin-left: $grp-margin-size-xl !important; margin-right: $grp-margin-size-xl !important; }
.grp-margin-horizontal-l { margin-left: $grp-margin-size-l !important; margin-right: $grp-margin-size-l !important; }
.grp-margin-horizontal-m { margin-left: $grp-margin-size-m !important; margin-right: $grp-margin-size-m !important; }
.grp-margin-horizontal { margin-left: $grp-margin-size !important; margin-right: $grp-margin-size !important; }
.grp-margin-horizontal-s { margin-left: $grp-margin-size-s !important; margin-right: $grp-margin-size-s !important; }
.grp-margin-horizontal-xs { margin-left: $grp-margin-size-xs !important; margin-right: $grp-margin-size-xs !important; }

.grp-no-margin { margin: 0 !important; }
.grp-no-margin-top { margin-top: 0 !important; }
.grp-no-margin-right { margin-right: 0 !important; }
.grp-no-margin-bottom { margin-bottom: 0 !important; }
.grp-no-margin-left { margin-left: 0 !important; }

.grp-padding-xl { padding: $grp-padding-size-xl !important; }
.grp-padding-l { padding: $grp-padding-size-l !important; }
.grp-padding-m { padding: $grp-padding-size-m !important; }
.grp-padding { padding: $grp-padding-size !important; }
.grp-padding-s { padding: $grp-padding-size-s !important; }
.grp-padding-xs { padding: $grp-padding-size-xs !important; }

.grp-padding-top-xl { padding-top: $grp-padding-size-xl !important; }
.grp-padding-top-l { padding-top: $grp-padding-size-l !important; }
.grp-padding-top-m { padding-top: $grp-padding-size-m !important; }
.grp-padding-top { padding-top: $grp-padding-size !important; }
.grp-padding-top-s { padding-top: $grp-padding-size-s !important; }
.grp-padding-top-xs { padding-top: $grp-padding-size-xs !important; }

.grp-padding-bottom-xl { padding-bottom: $grp-padding-size-xl !important; }
.grp-padding-bottom-l { padding-bottom: $grp-padding-size-l !important; }
.grp-padding-bottom-m { padding-bottom: $grp-padding-size-m !important; }
.grp-padding-bottom { padding-bottom: $grp-padding-size !important; }
.grp-padding-bottom-s { padding-bottom: $grp-padding-size-s !important; }
.grp-padding-bottom-xs { padding-bottom: $grp-padding-size-xs !important; }

.grp-padding-left-xl { padding-left: $grp-padding-size-xl !important; }
.grp-padding-left-l { padding-left: $grp-padding-size-l !important; }
.grp-padding-left-m { padding-left: $grp-padding-size-m !important; }
.grp-padding-left { padding-left: $grp-padding-size !important; }
.grp-padding-left-s { padding-left: $grp-padding-size-s !important; }
.grp-padding-left-xs { padding-left: $grp-padding-size-xs !important; }

.grp-padding-right-xl { padding-right: $grp-padding-size-xl !important; }
.grp-padding-right-l { padding-right: $grp-padding-size-l !important; }
.grp-padding-right-m { padding-right: $grp-padding-size-m !important; }
.grp-padding-right { padding-right: $grp-padding-size !important; }
.grp-padding-right-s { padding-right: $grp-padding-size-s !important; }
.grp-padding-right-xs { padding-right: $grp-padding-size-xs !important; }

.grp-padding-vertical-xl { padding-top: $grp-padding-size-xl !important; padding-bottom: $grp-padding-size-xl !important; }
.grp-padding-vertical-l { padding-top: $grp-padding-size-l !important; padding-bottom: $grp-padding-size-l !important; }
.grp-padding-vertical-m { padding-top: $grp-padding-size-m !important; padding-bottom: $grp-padding-size-m !important; }
.grp-padding-vertical { padding-top: $grp-padding-size !important; padding-bottom: $grp-padding-size !important; }
.grp-padding-vertical-s { padding-top: $grp-padding-size-s !important; padding-bottom: $grp-padding-size-s !important; }
.grp-padding-vertical-xs { padding-top: $grp-padding-size-xs !important; padding-bottom: $grp-padding-size-xs !important; }

.grp-padding-horizontal-xl { padding-left: $grp-padding-size-xl !important; padding-right: $grp-padding-size-xl !important; }
.grp-padding-horizontal-l { padding-left: $grp-padding-size-l !important; padding-right: $grp-padding-size-l !important; }
.grp-padding-horizontal-m { padding-left: $grp-padding-size-m !important; padding-right: $grp-padding-size-m !important; }
.grp-padding-horizontal { padding-left: $grp-padding-size !important; padding-right: $grp-padding-size !important; }
.grp-padding-horizontal-s { padding-left: $grp-padding-size-s !important; padding-right: $grp-padding-size-s !important; }
.grp-padding-horizontal-xs { padding-left: $grp-padding-size-xs !important; padding-right: $grp-padding-size-xs !important; }

.grp-no-padding { padding: 0 !important; }
.grp-no-padding-top { padding-top: 0 !important; }
.grp-no-padding-right { padding-right: 0 !important; }
.grp-no-padding-bottom { padding-bottom: 0 !important; }
.grp-no-padding-left { padding-left: 0 !important; }