.grp-messagelist li {
    position: relative;
}
.grp-messagelist .message-close {
    position: absolute;
    right: 10px;
    top: 1px;
}
.grp-messagelist .error .message-close,
.grp-messagelist .warning .message-close {
    color: #fff;
    opacity: 0.8;
    filter: alpha(opacity=80);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
.grp-messagelist .message-close {
    border: 0;
    height: auto;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    font: normal bold 20px/20px "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.grp-messagelist .message-close {
    float: right;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.3;
    filter: alpha(opacity=30);
}

.grp-messagelist .grp-password-expiration .message-close,
.grp-messagelist .password-expiration .message-close {
    display: none;
}
