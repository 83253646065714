


/*  LABELS
------------------------------------------------------------------------------------------------------ */

@mixin grp-label {
    display: inline-block;
    font-family: $grp-form-label-font-family;
    font-size: $grp-form-label-font-size;
    line-height: $grp-form-label-line-height;
    color: $grp-form-label-font-color;
    &.required {
        @include grp-form-label-required;
    }
}
fieldset.grp-module {
    .grp-row label {
        margin: 6px 0 6px;
        @include grp-label;
    }
}



/*  FORM FIELDS
------------------------------------------------------------------------------------------------------ */

@mixin grp-form-field {
    margin: 0;
    @include grp-padding($grp-padding-size-xs $grp-padding-size-s);
    height: 25px;
    font-family: $grp-form-field-font-family;
    font-size: $grp-form-field-font-size;
    line-height: $grp-form-field-line-height;
    font-weight: bold;
    color: $grp-form-field-font-color;
    border: $grp-form-field-border;
    @include border-radius($grp-form-field-border-radius);
    background: $grp-form-field-background-color;
    @include box-sizing(border-box);
    @include grp-form-field-box-shadow;
    overflow: hidden;
    vertical-align: middle;
    &:focus,
    &.grp-state-focus {
        border: $grp-form-field-border-focus;
        @include grp-form-field-box-shadow-focus;
        background: $grp-form-field-background-color-focus;
        outline: 0;
    }
}
input[type="text"],
input[type="password"],
input[type="url"],
input[type="email"],
input[type="number"],
input[type="submit"],
input[type="reset"],
textarea,
select {
    @include grp-form-field;
    .grp-errors & {
        border-color: $grp-font-color-error;
    }
}


/*  Readonly/Disabled ................................................... */

input[readonly],
input[disabled],
textarea[readonly],
select[disabled] {
    // Appearing as a form field with dotted border and no background
    border: $grp-form-field-border !important;
    border-style: dotted !important;
    background: transparent !important;
    &:focus {
        @include box-shadow(none);
    }
    // Variant: same style as p.grp-readonly (no border, no background)
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    // border: 0 !important;
    // background: transparent !important;
    // &:focus {
    //     @include box-shadow(none);
    //     border: 0 !important;
    //     background: transparent !important;
    // }
}
div.grp-readonly {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 4px 5px 3px !important;
    min-width: 106px;
    max-width: 746px;
    min-height: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: #555555;
    border: 1px dotted #ccc;
    @include border-radius($grp-form-field-border-radius);
    // @include box-sizing(border-box);
    * {
        white-space: normal !important; // reset white-space:nowrap e.g. given in .grp-tabular
    }
    pre {
        white-space: pre !important;
    }
    & + div.grp-readonly {
        margin-left: 20px;
    }
    &:empty {
        margin-bottom: -5px !important;
    }
}


/*  Form Field Errors ................................................... */

.grp-errors {
    label {
        color: $grp-font-color-error !important;
    }
    ul.radiolist.inline,
    ul.checkboxlist.inline {
        label {
            color: $grp-form-label-font-color !important;
        }
    }
    input[type="text"],
    input[type="password"],
    input[type="url"],
    input[type="email"],
    input[type="number"],
    input[type="submit"],
    input[type="reset"],
    textarea,
    select {
        border-color: $grp-font-color-error !important;
    }
    .selector {
        input,
        select,
        textarea {
            border: $grp-form-field-border !important;
        }
    }
    ul.errorlist {
        padding: 5px 0 0;
        color: $grp-font-color-error;
        font-size: 11px !important;
        line-height: 14px;
    }
}


/*  Select ................................................... */

select {
    padding: 4px 3px 4px 2px;
    min-width: 118px;
    // -moz-appearance: none; // not implemented in -moz yet, see https://bugzilla.mozilla.org/show_bug.cgi?id=649849
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    select:focus {
        padding: 4px 28px 4px 5px;
        -webkit-appearance: none;
        background-image: url('../images/icons/form-select.png');
        background-position: 100% 50%;
        background-repeat: no-repeat;
    }
}
select[multiple] {
    padding-right: 5px;
    height: 160px;
    overflow: auto;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select[multiple] {
        background-image: none;
    }
}


/*  Textarea ................................................... */

textarea {
    vertical-align: top;
    padding: 5px 5px;
    height: 60px;
    overflow: auto;
}
fieldset.monospace textarea {
    font-family: "Bitstream Vera Sans Mono",Monaco,"Courier New",Courier,monospace;
}


/*  Checkboxes, Radiobuttons ................................................... */

.grp-row input[type=checkbox],
.grp-row input[type=radio] {
    position: relative;
    top: 1px;
    & + label {
        position: relative;
        margin: 0 0 0 5px;
    }
}



/*  SEARCH
------------------------------------------------------------------------------------------------------ */

input[type=text].grp-search-field {
    margin-right: -5px;
    padding-left: 10px;
    padding-right: 30px;
    @include border-radius(20px);
}



/*  RADIO- & CHECKBOX-LISTS
------------------------------------------------------------------------------------------------------ */

@mixin radio-checkbox-list {
    position: relative;
    float: none;
    display: inline-block;
    margin: 5px 0 0;
    padding: 0;
    font-size: 11px;
    line-height: 15px;
    font-weight: normal;
    label {
        float: none;
        display: inline-block;
        margin: 0 !important;
        padding: 0 !important;
        width: auto !important;
        white-space: nowrap;
        input {

        }
    }
    li + li {
        margin-top: 2px;
    }
}
@mixin radio-checkbox-list-inline {
    @include radio-checkbox-list;
    max-width: 760px;
    float: left;
    display: inline;
    margin-top: 5px;
    margin-bottom: 3px;
    padding-right: 20px;
    li {
        float: left;
        display: inline;
        margin-top: 0 !important;
        margin-bottom: 2px;
        padding-right: 20px;
    }
}
ul.radiolist,
ul.checkboxlist {
    @include radio-checkbox-list;
}

.grp-row > ul.radiolist,
.grp-row > ul.checkboxlist {
    margin: 0;
}

ul.radiolist.inline, ul.checkboxlist.inline {
    @include radio-checkbox-list-inline;
}

.grp-module.grp-tbody ul.radiolist.inline, .grp-module.grp-tbody ul.checkboxlist.inline {
    white-space: normal;
    li {
        position: relative;
        float: left;
        display: inline;
    }
}

.grp-row.grp-cells ul.radiolist.inline li, .grp-row.grp-cells ul.checkboxlist.inline li {
    float: none;
}



/*  SELECTORS
------------------------------------------------------------------------------------------------------ */

.selector {
    position: relative;
    float: left;
    overflow: hidden;
    width: 758px;
    .selector-available,
    .selector-chosen {
        float: left;
        width: 366px;
        border: 1px solid #ccc;
        @include border-radius($grp-border-radius);
        background: #ddd;
        &.stacked {
            width: 756px;
        }
        h2 {
            padding: 7px 5px 6px 7px;
            font-size: 12px;
            line-height: 13px;
            font-weight: bold;
            img {
                display: none;
            }
        }
    }
    ul.selector-chooser {
        float: left;
        margin: 110px 2px 0;
        padding: 0;
        width: 18px;
    }
    .selector-chosen h2 {
        border-bottom: 0 !important;
    }
    .selector-filter {
        display: block !important;
        height: 27px;
        padding: 3px 5px 2px 2px;
        font-weight: bold;
        color: #666;
        border-top: 1px solid #e4e4e4;
        border-bottom: 1px solid #e4e4e4;
        @include border-top-radius($grp-border-radius);
        line-height: 25px;
        text-indent: 25px;
        background: url('../images/icons/searchbox.png') 6px 50% no-repeat;
        label {
            display: none !important;
        }
        input[type=text] {
            position: relative;
            margin: 0;
            width: 326px !important;
            max-width: 326px !important;
        }
        img {
            display: none;
        }
        h2 + select {
            position: relative;
            top: -1px;
        }
    }
    select[multiple] {
        margin: 0 0 0 -1px;
        padding-left: 3px;
        max-width: 368px !important;
        width: 368px !important;
        height: 200px;
        @include border-radius(0);

    }
    .selector-chosen select[multiple] {
        height: 235px !important;
    }
    a.selector-add {
        background-image: url('../images/icons/selector-add-m2m-horizontal.png');
        &:hover {
            background-image: url('../images/icons/selector-add-m2m-horizontal_hover.png');
        }
    }
    a.selector-remove {
        background-image: url('../images/icons/selector-remove-m2m-horizontal.png');
        &:hover {
            background-image: url('../images/icons/selector-remove-m2m-horizontal_hover.png');
        }
    }
    a.selector-chooseall, a.selector-clearall {
        display: block;
        margin: 0;
        padding: 2px 7px;
        font-size: 11px;
        line-height: 13px;
        font-weight: bold;
    }
    &.stacked {
        .selector-available,
        .selector-chosen {
            width: 756px;
        }
        .selector-filter input[type=text] {
            width: 716px !important;
            max-width: 716px !important;
        }
        .selector-chosen .selector-filter:after {
            content: " " url('../images/icons/selector-add-m2m-vertical_hover.png');
        }
        select[multiple] {
            width: 758px !important;
            max-width: 758px !important;
        }
        ul.selector-chooser {
            margin: 4px 0 0 356px;
            width: 36px;
            li {
                float: left;
            }
        }
        a.selector-add {
            background-image: url('../images/icons/selector-add-m2m-vertical.png');
            &:hover {
                background-image: url('../images/icons/selector-add-m2m-vertical_hover.png');
            }
        }
        a.selector-remove {
            background-image: url('../images/icons/selector-remove-m2m-vertical.png');
            &:hover {
                background-image: url('../images/icons/selector-remove-m2m-vertical_hover.png');
            }
        }
    }
    a.selector-add, a.selector-remove {
        display: block;
        width: 18px;
        height: 18px;
        color: transparent !important;
        background-position: 50% 0;
        background-repeat: no-repeat;
    }
}
ul.errorlist + .selector {
    margin-top: 8px !important;
}



/*  ERRORNOTE & -LIST
------------------------------------------------------------------------------------------------------ */

@mixin errornote {
    position: relative;
    float: left;
    clear: both;
    margin: 0 0 $grp-margin-size-s;
    @include grp-padding($grp-padding-size-s $grp-padding-size);
    width: 100%;
    @include box-sizing(border-box);
    color: #fff;
    font-weight: bold;
    @include border-radius($grp-border-radius);
    background: $grp-font-color-error;
}
@mixin errorlist {
    @include legacy-pie-clearfix;
    font-size: 11px;
    line-height: 13px;
    font-weight: bold;
    color: $grp-font-color-error;
    white-space: normal;
}
@mixin errorlist-nonform {
    @include errorlist;
    margin: 5px 0 0;
    li {
        padding: 5px 10px;
        & + li {
            border-top: 1px solid $grp-font-color-error;
        }
    }
}
@mixin errorlist-field {
    @include errorlist;
    margin: 0;
    li {
        padding: 2px 0 0;
        border-top: 0 !important;
        &:first-child {
            padding-top: 0;
        }
    }
}

p.errornote {
    @include errornote;
    & + ul.errorlist {
        @include errorlist-nonform;
        margin: -5px 0 0;
    }
}
ul.errorlist {
    @include errorlist;
    + ul.errorlist {
        padding-top: 2px;
    }

}
p.preview + ul.errorlist {
    margin-top: 5px !important;
}
p.preview + ul.errorlist {
    margin-top: 5px !important;
}


/*  Errorlist in rows ................................................... */

.grp-row {
    ul.errorlist {
        @include errorlist-field;
    }
}


/*  Errornote & -list in tabular groups ................................................... */

.grp-tabular {
    p.errornote {
        margin: 2px 0 0;
        & + ul.errorlist {
            margin: 0;
        }
    }
    ul.errorlist {
        @include errorlist-nonform;
    }
    .grp-tbody ul.errorlist  {
        margin: 0;
    }
    .grp-td ul.errorlist {
        clear: both;
        @include errorlist-field;
    }
}


/*  Errornote & -list in stacked groups ................................................... */

.grp-stacked {
    p.errornote {
        margin: 0;
        & + ul.errorlist {
            margin: 0;
        }
    }
    ul.errorlist {
        @include errorlist-nonform;
        margin: 3px 0;
    }
    h3 + * + ul.errorlist {
        @include errorlist-field;
        margin: 0 !important;
        padding: 5px 10px 8px;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #ddd;
    }
    .grp-row ul.errorlist {
        @include errorlist-field;
    }
}


/*  Errorlist after add-another (e.g. with radiolist-inline) ................................................... */

.grp-errors {
    a.add-another + ul.errorlist {
        clear: both;
    }
}


/*  Errors with tinyMCE ................................................... */

.grp-errors {
    td.mceIframeContainer {
        border: 1px solid $grp-font-color-error !important;
        @include border-radius($grp-border-radius);
    }
}



/*  FORM FIELDS: BASIC DIMENSIONS
------------------------------------------------------------------------------------------------------ */

input[type=text],
input[type=password],
input[type="number"],
.vDateField,
.vTimeField,
.vIntegerField,
.vPositiveSmallIntegerField,
.vManyToManyRawIdAdminField,
.vForeignKeyRawIdAdminField {
    width: 118px;
}

input.grp-has-related-lookup,
// input.vManyToManyRawIdAdminField,
// input.vForeignKeyRawIdAdminField,
// input.vRelatedGeneric,
input.vDateField.hasDatepicker,
input.vTimeField.hasTimepicker,
input.vFileBrowseField {
    padding-right: 24px !important;
}

input[type="url"],
input[type="email"],
input.vTextField,
input.vURLField,
input.vFileBrowseField,
textarea,
.vLargeTextField,
.vXMLLargeTextField {
    width: 278px;
}

.row select {
    min-width: 118px;
}

.vLargeTextField {
    height: 118px;
}




/*  FORM FIELDS: LARGE DIMENSIONS
------------------------------------------------------------------------------------------------------ */

.grp-row input[type="url"],
.grp-row input[type="email"],
.grp-row .vTextField,
.grp-row .vURLField,
.grp-row .vFileBrowseField,
.grp-row textarea,
.grp-row .vLargeTextField,
.grp-row .vXMLLargeTextField,
.grp-autocomplete-wrapper-m2m {
    width: 758px;
}
.grp-row select {
    max-width: 758px;
}
.grp-autocomplete-wrapper-m2m ul.grp-repr,
.grp-autocomplete-wrapper-m2m ul.grp-repr li {
    max-width: 700px;
}



/*  FORM FIELDS: DIMENSION IN CHANGELIST TABLE & TABULAR GROUPS
------------------------------------------------------------------------------------------------------ */

.grp-changelist-results table.grp-table {
    input[type="url"],
    input[type="email"],
    .vTextField,
    .vURLField,
    .vFileBrowseField,
    textarea,
    .vLargeTextField,
    .vXMLLargeTextField,
    select {
        max-width: 278px;
    }
}
.grp-module.grp-table select,
.grp-module.grp-table .grp-autocomplete-wrapper-m2m,
.grp-module.grp-table .grp-autocomplete-wrapper-fk {
    max-width: 278px;
}
.grp-module.grp-table .grp-autocomplete-wrapper-m2m,
.grp-module.grp-table .grp-autocomplete-wrapper-fk {
    width: 278px;
}
.grp-module.grp-table .grp-autocomplete-wrapper-m2m ul.grp-repr,
.grp-module.grp-table .grp-autocomplete-wrapper-m2m ul.grp-repr li {
    max-width: 222px;
}



/*  FORM FIELDS IN CELLS
------------------------------------------------------------------------------------------------------ */

.grp-cell input[type="url"],
.grp-cell input[type="email"],
.grp-cell input[type="number"],
.grp-cell input[type=text],
.grp-cell input[type=password],
.grp-cell select,
.grp-cell input[readonly],
.grp-cell input[disabled],
.grp-cell textarea[readonly],
.grp-cell select[disabled],
.grp-cell .grp-autocomplete-wrapper-m2m,
.grp-cell .grp-autocomplete-wrapper-fk {
    max-width: 278px;
}

.grp-cell .grp-autocomplete-wrapper-m2m ul.grp-repr,
.grp-cell .grp-autocomplete-wrapper-m2m ul.grp-repr li {
    max-width: 220px;
}

.grp-cell div.grp-readonly {
    max-width: 266px;
}
