span.cke_button__sectiondivider_label {
    display: inline;
}
span.cke_button__horizontalrule_label {
    visibility: hidden;
    width: 20px;
    display: inline;
}
span.cke_button_label.cke_button__horizontalrule_label:before {
    content: "<hr>";
    display: inline;
    visibility: visible;
}
span.cke_button__related_label {
    visibility: hidden;
    width: 45px;
    display: inline;
}
span.cke_button__related_label:before {
    content: "Related";
    display: inline;
    visibility: visible;
}


.cke_textarea_inline {
  margin: 0;
  padding: 5px;
  height: auto;
  font-family: "Helvetica Neue", Helvetica, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #222;
  border: 1px solid #aaa;
  border-radius: 3px;
  background: #fdfdfd;
  box-sizing: border-box;
  box-shadow: inset 0 1px 3px 0 #eee;
  overflow: hidden;
  vertical-align: middle;
  width: 618px;
}

.cke_textarea_inline.cke_focus {
  box-shadow: #3b99fc 0 0 3px 2px;
  outline: none;
}
