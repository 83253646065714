@import "compass";

$atl-rounded-button-height: 25px !default;
$atl-rounded-button-font-size: 12px !default;
$atl-rounded-button-font-weight: 500 !default;
$atl-rounded-button-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;
$atl-button-colors: (grey: (bg: #ddd, fg: #333), blue: (bg: #126fa0, fg: #fff), red: (bg: #971e16, fg: #fff));

@mixin atl-rounded-button($color: grey, $extra-padding-left: 0, $background: transparent) {
    $color-map: map-get($atl-button-colors, $color);
    @if $color-map == null {
        $color-map: map-get($atl-button-colors, grey);
    }
    $fg-color: map-get($color-map, fg);
    $bg-color: map-get($color-map, bg);
    &, &:visited, &:hover, &:active, &:focus {
        @if $color == grey {
            @include box-shadow(none);
        } @else {
            @include box-shadow(inset  0 1px 0 0 rgba(255, 255, 255, .5), 0 1px 2px rgba(0, 0, 0, .15));
        }
        @if $background == transparent {
            @include background(linear-gradient(lighten($bg-color, 7%), darken($bg-color, 7%)));
        } @else {
            @include background($background, linear-gradient(lighten($bg-color, 7%), darken($bg-color, 7%)));
        }
        background-color: $bg-color;
        @include inline-block(middle);
        @include border-radius(5px);
        border: 1px solid darken($bg-color, 20%);
        opacity: 1 !important;
        height: $atl-rounded-button-height;
        @include box-sizing(border-box);
        line-height: $atl-rounded-button-height - 4;
        padding: 1px ($atl-rounded-button-height * .5) 1px (($atl-rounded-button-height * .5) + $extra-padding-left);
        font-weight: $atl-rounded-button-font-weight;
        font-size: $atl-rounded-button-font-size;
        font-family: $atl-rounded-button-font-family;
        color: $fg-color;
        margin: 0;
        text-decoration: none;
        text-align: center;
        @if $color == grey {
            @include text-shadow(0 -1px 0 rgba(255, 255, 255, 0.35));
        } @else {
            @include text-shadow(0 -1px 0 rgba(0, 0, 0, 0.35));
        }
        @include user-select(none);
        cursor: pointer;
        position: relative;
        -webkit-appearance: none;
    }

    &.small {
        height: ($atl-rounded-button-height * 0.8);
        line-height: ($atl-rounded-button-height * 0.8) - 4;
        padding: 1px 6px 1px (6px + $extra-padding-left);
    }

    &:hover, &:focus, &:active, &.is-active, &.active {
        text-decoration: none;
    }

    &:hover, &:focus {
        color: $fg-color;
        background-color: $bg-color;
        border: 1px solid darken($bg-color, 20%) !important;
        @if $background == transparent {
            @include background(linear-gradient(lighten($bg-color, 14%), darken($bg-color, 7%)));
        } @else {
            @include background($background, linear-gradient(lighten($bg-color, 14%), darken($bg-color, 7%)));
        }
    }
    &:active, &.is-active, &.active {
        @if $color == grey {
            @include box-shadow(none);
        } @else {
            @include box-shadow(inset  0 1px 3px 0 rgba(0, 0, 0, .3), 0 1px 2px rgba(0, 0, 0, .15));
        }
        @if $color == grey {
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.35);
        } @else {
            text-shadow: 0 1px 0 rgba(darken($bg-color, 30%), 0.35);
        }
        @if $background == transparent {
            @include background(linear-gradient(darken($bg-color, 7%), lighten($bg-color, 10%)));
        } @else {
            @include background($background, linear-gradient(darken($bg-color, 7%), lighten($bg-color, 10%)));
            background-position: 0 1px;
        }
        padding-top: 2px;
        padding-bottom: 0;
    }
    &:focus {
        outline: none;
    }

    &.disabled,
    &.disabled:hover,
    &.disabled:focus,
    &.disabled:active,
    &:disabled {
        @include box-shadow(0 1px 2px rgba(0, 0, 0, .10));
        @include opacity(.8);
        background: #eee;
        border: 1px solid #ddd !important;
        @include text-shadow(0 1px 1px rgba(255, 255, 255, 1));
        color: #ccc;
        cursor: default;
        -webkit-appearance: none;
    }
}
