


/*  TYPOGRAPHIC ELEMENTS
------------------------------------------------------------------------------------------------------ */


/*  Help ......................................... */

p.grp-help {
    max-width: 758px;
    padding: 5px 0 0;
    color: lighten($grp-font-color-quiet, 7);
    font-size: 11px !important;
    line-height: 13px;
    white-space: normal !important;
    &:first-child {
        margin-top: 5px;
    }
    .errorlist + & {
        padding-top: 2px;
    }
}
.grp-cells p.grp-help,
.grp-td p.grp-help {
    max-width: 278px;
}
.grp-row p.grp-help,
.grp-td p.grp-help {
    &:first-child {
        margin: -2px 0 8px;
    }
}
.grp-row p.grp-help {
    margin-bottom: -2px;
}
.grp-related-widget-wrapper + p.grp-help {
    position: relative;
    clear: left;
    margin-top: 5px;
}


/*  Help Tooltip ......................................... */

img.grp-help-tooltip {
    opacity: .5;
    .grp-table & {
        position: absolute;
        right: 18px;
        top: 3px;
    }
}


/*  Description ......................................... */

.grp-description {
    font-size: 11px;
}


/*  Read Only (transferred to _forms.scss) ......................................... */

// p.grp-readonly {
//     position: relative;
//     display: inline-block;
//     margin: 4px 0 0 !important;
//     padding: 0 !important;
//     color: #666;
//     font-size: 12px;
//     font-weight: bold;
//     & + p.grp-readonly {
//         margin-left: 20px;
//     }
// }
// .grp-tabular {
//     p.grp-readonly {
//         margin-top: 5px !important;
//     }
// }


/*  Images ......................................... */

.grp-row img {
    font-size: 1px;
    line-height: 1px;
    vertical-align: middle;
}


/*  FB-Show ......................................... */

.fb_show + p.grp-help a {
    display: inline-block;
    padding: 3px;
    font-size: 0;
    line-height: 0;
}
.fb_show + p.grp-help a img {
    margin: 0;
    font-size: 0;
    line-height: 0;
}


/*  File Uploads & Preview Images ......................................... */

p.file-upload {
    margin: 6px 0 3px;
    font-size: 11px;
    line-height: 14px;
    span.clearable-file-input {
        display: block;
        margin: 5px 0 -12px;
        input {
            margin: 1px 0 0;
        }
        label {
            margin: 0 0 0 5px;
        }
    }
    tr & {
        margin: 1px 0 -2px;
        line-height: 13px; // Firefox needs this, otherwise related-lookup buttons in context have an offset
    }
}
p.preview {
    margin: 5px 0 0;
    tr & {
        margin: 9px 0 -5px;
    }
    a {
        display: inline-block;
        padding: 3px;
        font-size: 0;
        line-height: 0;
        border: 1px solid $grp-link-color;
        @include border-radius($grp-border-radius-s);
        &:hover {
            border: 1px solid $grp-link-color-hover;
        }
    }
}


/*  RTE Rich Text Edited ......................................... */

.grp-rte {
    font-size: 13px;
    line-height: 18px;
    h4 {
        margin: 5px 0;
    }
    p, ul, ol, blockquote, dl, dt, dd {
        margin: 10px 0;
        &:only-child {
            margin: 5px 0;
        }
    }
    ul {
        margin-left: 30px;
        li {
            margin-left: 20px;
            list-style-type: disc;
            list-style-position: outside;
            ul {
                margin-top: -5px !important;
                li {
                    list-style-type: circle;
                }
            }
        }
    }
}


/*  Docutils ......................................... */

.grp-docutils {
    .grp-module {
        h4 {
            padding: 0;
            font-size: 13px;
            border: 0;
            background: none;
            p {
                margin: 0;
            }
        }
    }
    table p {
        margin: 0 !important;
    }
    code, pre {
        font-size: 11px;
        font-family: "Bitstream Vera Sans Mono", Monaco, "Courier New", Courier, monospace;
    }
    pre.literal-block {
        margin: 10px;
        padding: 6px 8px;
        background: #fff;
    }
    .grp-group h2 + .grp-row > p {
        padding: 3px 10px 0;
    }
}

span.grp-anchor {
    position: relative;
    float: left;
    clear: both;
    top: -80px;
}
.grp-nowrap {
    white-space: nowrap;
}
